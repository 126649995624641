import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./fonts/Inria_Serif/InriaSerif-Bold.ttf";
import "./fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
