import React from "react";
import { Outlet, useNavigate, NavLink } from "react-router-dom";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faShoppingCart,
  faPlus,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  // const navigate = useNavigate();

  return (
    <div className="fixed bottom-0 left-0 w-full h-16 bg-[#EC7D5A] flex justify-around items-center">
      <NavLink exact to="/" className="nav-item" activeClassName="text">
        <FontAwesomeIcon icon={faHome} size="lg" />
      </NavLink>
      <NavLink to="/market" className="nav-item" activeClassName="active">
        <FontAwesomeIcon icon={faShoppingBasket} size="lg" />
      </NavLink>
      <NavLink to="/make-listing" className="nav-item" activeClassName="active">
        <FontAwesomeIcon icon={faPlus} size="lg" />
      </NavLink>
    </div>
  );
};

export default Navbar;
