import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import {
  getIdToken,
  getAuth,
  setPersistence,
  initializeAuth,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD_eN4PGvP3Em6C5-SlZ1m2L8PhjtrGFWA",
  authDomain: "fiko-fa39f.firebaseapp.com",
  projectId: "fiko-fa39f",
  storageBucket: "fiko-fa39f.appspot.com",
  messagingSenderId: "74906028867",
  appId: "1:74906028867:web:4db7d89e1c0b2e577cfdf6",
  measurementId: "G-413T1BHPCQ",
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase
initializeAuth(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

// TAKES A FILE AND UPLOADS IT TO FIREBASE STORAGE, LOOK AT THIS LINK FOR MORE INFO ON FIREBASE DOCS
//firebase.google.com/docs/storage/web/upload-files#web-modular-api_1
// const handleImageChange = (file) => {
//   if (file) {
//     // Create a storage reference for the file
//     const storageRef = ref(storage, "images/" + file.name);

//     // Upload the file to Firebase Storage
//     uploadBytes(storageRef, bytes).then((snapshot) => {
//       console.log("Uploaded an array!");
//     });
//   }
// };

// Function to add a document to a specified collection
export async function addDocumentToCollection(data) {
  try {
    const auth = getAuth();
    const idToken = await getIdToken(auth.currentUser);

    const response = await fetch(
      "https://us-central1-fiko-fa39f.cloudfunctions.net/addDocumentToCollectionNew",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jsonResponse = await response.json();
    console.log("Document added with ID:", jsonResponse.docId);
  } catch (e) {
    console.error("Error adding document:", e);
  }
}

export { app, auth, storage };

export default db;
