import React, { useState, useEffect } from "react";
import "./ProductForm.css";
import { app, auth, addDocumentToCollection } from "../functions/firestoreDB";
import {
  getAuth,
  onAuthStateChanged,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  getIdToken,
} from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../functions/firestoreDB";
import { Buffer } from "buffer";

const ProductForm = () => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [username, setUsername] = useState("");
  const [nphoneNumber, setPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const [previewImage, setPreviewImage] = useState(null);

  const [titleError, setTitleError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [confirm, setConfirm] = useState(null);
  const [code, setCode] = useState("");
  const [user, setUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

  useEffect(() => {
    const verifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );
    setRecaptchaVerifier(verifier);
  }, []);

  useEffect(() => {
    if (!auth) {
      return;
    } else {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
      });

      return () => unsubscribe();
    }
  }, [auth]);
  const convertImageToBase64 = (image) => {
    if (!image) {
      return null;
    }

    const dataURL = image;
    const base64ImageContent = dataURL.split(",")[1];
    return base64ImageContent;
  };

  const normalizePhoneNumber = (input) => {
    // Remove all non-digit characters
    const phoneNumber = input.replace(/\D+/g, "");
    let normalizedPhoneNumber;

    // Check if the number starts with a country code (assuming '1' for the US)
    if (phoneNumber.length > 10 && phoneNumber.startsWith("1")) {
      normalizedPhoneNumber = phoneNumber.substring(1);
    } else {
      normalizedPhoneNumber = phoneNumber;
    }

    // Validate that the remaining phone number has the correct length
    if (normalizedPhoneNumber.length !== 10) {
      return null;
    }

    return normalizedPhoneNumber;
  };

  const signInWithPhoneNumber = async () => {
    try {
      const normalizedPhoneNumber = normalizePhoneNumber(nphoneNumber);
      if (!normalizedPhoneNumber) {
        alert(
          "Invalid phone number format. Please enter a 10-digit phone number."
        );
        return;
      }

      if (recaptchaVerifier) {
        await recaptchaVerifier.verify();
      }
      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider.verifyPhoneNumber(
        `+1${normalizedPhoneNumber}`,
        recaptchaVerifier
      );
      setConfirm({ verificationId });
      setShowPopup(true);
    } catch (error) {
      console.error("Error sending verification code:", error);
      alert(
        "Error sending verification code. Please check your phone number and try again."
      );
    }
  };

  const confirmCode = async () => {
    try {
      const credential = PhoneAuthProvider.credential(
        confirm.verificationId,
        code
      );

      await signInWithCredential(auth, credential);
    } catch (error) {
      console.error("Error confirming code:", error);
      alert("Invalid code.");
    }
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();
    await confirmCode();
    setShowPopup(false);

    // Submit the form after successful authentication
    await submitForm();
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
  };

  let newphoneNumber = normalizePhoneNumber(nphoneNumber);
  const status = false;
  let phoneNumber = `+1${newphoneNumber}`;
  const submitForm = async () => {
    // Convert the base64 image to base32 before adding it to the productData object
    const base32Image = convertImageToBase64(image);

    const productData = {
      title,
      image: base32Image,
      description,
      price,
      username,
      phoneNumber,
      status,
    };
    productData.id = Date.now().toString();
    await addDocumentToCollection(productData);

    // Clear the form and error
    setTitle("");
    setImage(null);
    setDescription("");
    setPrice("");
    setUsername("");
    setPhoneNumber("");
    setError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Reset error states
    setTitleError(false);
    setImageError(false);
    setDescriptionError(false);
    setPriceError(false);
    setUsernameError(false);
    setPhoneNumberError(false);

    // Validate the form fields
    let hasError = false;
    if (!title) {
      setTitleError(true);
      hasError = true;
    }
    if (!image) {
      setImageError(true);
      hasError = true;
    }
    if (!description) {
      setDescriptionError(true);
      hasError = true;
    }
    if (!price) {
      setPriceError(true);
      hasError = true;
    }

    if (!nphoneNumber) {
      setPhoneNumberError(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    setIsSubmitting(true);
    if (user) {
      // User is authenticated, submit the form
      await submitForm();
    } else {
      // User is not authenticated, initiate phone number authentication
      await signInWithPhoneNumber();
    }

    setIsSubmitting(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="px-8 pb-8 mt-2 flex-col justify-center items-center flex bg-transparent">
      <p className="font-dmserif mt-2 text-black text-2xl font-normal tracking-wider text-center bg-transparent">
        Create Product Listing
      </p>
      <div className="bg-white rounded-lg p-4">
        <form onSubmit={handleSubmit} className="items-center flex-col flex">
          <label className="font-inriar mt-2 text-black text-xl font-normal tracking-wider text-start bg-transparentw w-full items-center">
            Listing Name:
            <div className="custom-input-container mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // width="400"
                height="40"
                viewBox="0 0 290 40"
                fill="none"
              >
                <path
                  d="M0.113249 20L3 22.8868L5.88675 20L3 17.1132L0.113249 20ZM289.887 20L287 17.1132L284.113 20L287 22.8868L289.887 20ZM3 20.5H287V19.5H3V20.5Z"
                  fill="black"
                />
              </svg>
              <input
                type="text"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                className={`custom-input ${
                  titleError ? "input-error" : ""
                } text-lg`}
              />
            </div>
          </label>
          <br />
          <label className="font-inriar mt-2 text-black text-lg font-thin tracking-wider text-start bg-transparent">
            Image:
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className={imageError ? "input-error" : ""}
            />
          </label>
          {previewImage && (
            <img
              src={previewImage}
              alt="Product preview"
              className="image-preview"
            />
          )}
          <br />

          <label className="font-inriar mt-2 text-black text-lg font-thin tracking-wider text-start bg-transparent">
            Description:
            <input
              type="text"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              className={`mt-2 ${descriptionError ? "input-error" : ""}`}
            />
          </label>
          <br />
          <label className="font-inriar mt-2 text-black text-xl font-normal tracking-wider text-start bg-transparentw w-full items-center">
            Price:
            <div className="custom-input-container mt-4">
              <div className="flex flex-row bg-transparent">
                <p className="bg-transparent text-black top-[-8px] left-[-14px] absolute">
                  $
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // width="400"
                  height="40"
                  viewBox="0 0 290 40"
                  fill="none"
                >
                  <path
                    d="M0.113249 20L3 22.8868L5.88675 20L3 17.1132L0.113249 20ZM289.887 20L287 17.1132L284.113 20L287 22.8868L289.887 20ZM3 20.5H287V19.5H3V20.5Z"
                    fill="black"
                  />
                </svg>
                <input
                  type="text"
                  value={price}
                  onChange={(event) => setPrice(event.target.value)}
                  className={`custom-input ${
                    priceError ? "input-error" : ""
                  } text-lg`}
                />
              </div>
            </div>
          </label>
          <br />
          <label className="font-inriar mt-2 text-black text-xl font-normal tracking-wider text-start bg-transparentw w-full items-center">
            Phone Number:
            <div className="custom-input-container mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // width="400"
                height="40"
                viewBox="0 0 290 40"
                fill="none"
              >
                <path
                  d="M0.113249 20L3 22.8868L5.88675 20L3 17.1132L0.113249 20ZM289.887 20L287 17.1132L284.113 20L287 22.8868L289.887 20ZM3 20.5H287V19.5H3V20.5Z"
                  fill="black"
                />
              </svg>
              <input
                type="text"
                value={nphoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                className={`custom-input ${
                  phoneNumberError ? "input-error" : ""
                } text-lg`}
              />
            </div>
          </label>

          <br />
          <button type="submit" disabled={isSubmitting}>
            <div className="bg-[#EC7D5A] mt-4 shadow-custom justify-center items-center py-2.5 px-7 rounded-lg">
              <p className="font-inria text-white text-xl font-normal tracking-wder text-center">
                {isSubmitting ? "Submitting..." : "Submit"}
              </p>
            </div>
          </button>
        </form>
      </div>
      <div id="recaptcha-container"></div>
      {showPopup && (
        <div className="popup bg-white">
          <div className="popup-content bg-transparent">
            <form onSubmit={handlePopupSubmit}>
              <label
                className="font-dmserif mt-2 text-black text-xl font-normal tracking-wider text-center"
                htmlFor="confirmation-code"
              >
                Confirmation Code:
              </label>
              <input
                type="text"
                id="confirmation-code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
                className="focus:outline-none mt-2 border-0 border-none font-dmserif text-black text-lg font-normal tracking-wider"
              />
              <button
                className="bg-[#EC7D5A] mt-4 justify-center items-center py-2.5 px-7 rounded-lg text-white"
                type="submit"
              >
                Submit
              </button>
              <button
                className="bg-[#EC7D5A] mt-4 justify-center items-center py-2.5 px-7 rounded-lg text-white"
                type="button"
                onClick={handlePopupCancel}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductForm;
