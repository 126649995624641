import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Market from "./pages/Market";
import MakeListing from "./pages/MakeListing";
import Navbar from "./components/Navbar";
import "@fortawesome/fontawesome-free/css/all.css";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="bg-custom-gradient flex flex-col h-max min-h-screen">
        <header className="bg-transparent pt-4">
          <h1 className="text-white font-inria font-bold text-5xl">Fikko</h1>
        </header>
        <div className="bg-transparent flex-grow">
          <Routes style={{ paddingBottom: "60px" }}>
            <Route path="/" element={<Home />} />
            <Route path="/market" element={<Market />} />
            <Route path="/make-listing" element={<MakeListing />} />
          </Routes>
          <div style={{ marginBottom: "100px" }}></div>
        </div>
        <Navbar />
      </div>
    </Router>
  );
}

export default App;
