import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { getDocs, collection } from "firebase/firestore";
import { query, orderBy, startAfter, limit } from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signOut,
} from "firebase/auth";
import { getDownloadURL, ref } from "firebase/storage";
import database, { storage } from "../functions/firestoreDB";
import "./Market.css";

function Market() {
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState([]);
  const [user, setUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [code, setCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  const [page, setPage] = useState(1); // <- For tracking which batch/page of data we're on
  const isNearBottom = useInfiniteScroll(); // <- Custom hook to check if user scrolled near the bottom

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    const itemsRef = collection(database, "orders");
    let itemsCollection = query(itemsRef, orderBy("id"), limit(10));

    if (page > 1 && items.length > 0) {
      itemsCollection = query(
        itemsRef,
        orderBy("id"),
        startAfter(items[items.length - 1].doc),
        limit(3)
      );
    }

    const snapshot = await getDocs(itemsCollection);
    const itemsList = [];
    if (snapshot.docs.length < 10) {
      setHasMoreData(false);
    }
    for (const doc of snapshot.docs) {
      const imageName = doc.data().image;
      const imageURL = await fetchImageData(imageName);

      const item = {
        id: doc.id,
        title: doc.data().title,
        image: imageURL,
        price: doc.data().price,
        phoneNumber: doc.data().phoneNumber,
        doc,
      };

      if (!items.some((existingItem) => existingItem.id === item.id)) {
        itemsList.push(item);
      }
    }

    setItems((prevItems) => [...prevItems, ...itemsList]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isNearBottom && !isLoading && hasMoreData) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [isNearBottom, isLoading, hasMoreData]);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []); // Remove isNearBottom dependency here

  useEffect(() => {
    const verifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      getAuth()
    );
    setRecaptchaVerifier(verifier);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  async function fetchImageData(imageName) {
    const storageRef = ref(storage, `${imageName}`);
    const imageURL = await getDownloadURL(storageRef);
    return imageURL;
  }

  const moveItemToSold = async (itemId) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAuth().currentUser.getIdToken()}`,
      },
      body: JSON.stringify({ documentId: itemId }),
    };
    const response = await fetch(
      "https://us-central1-fiko-fa39f.cloudfunctions.net/moveToSold",
      requestOptions
    );

    if (response.status === 403) {
      signOut(getAuth());
      alert("Error: Unauthorized. You have been signed out.");
    } else if (!response.ok) {
      alert(`Error: ${response.statusText}`);
    } else {
      setItems(items.filter((item) => item.id !== itemId));
    }
  };

  const handleDelete = async (itemId) => {
    if (!user) {
      setSelectedItemId(itemId);
      setShowPopup(true);
      return;
    }

    await moveItemToSold(itemId);
  };

  const signInWithPhoneNumber = async () => {
    try {
      if (recaptchaVerifier) {
        await recaptchaVerifier.verify();
      }
      const phoneProvider = new PhoneAuthProvider(getAuth());
      const verificationId = await phoneProvider.verifyPhoneNumber(
        `+1${phoneNumber}`,
        recaptchaVerifier
      );
      setConfirm({ verificationId });
      setShowConfirmationCode(true);
    } catch (error) {
      console.error("Error sending verification code:", error);
      alert(
        "Error sending verification code. Please check your phone number and try again."
      );
      setShowConfirmationCode(false);
    }
  };

  const confirmCode = async () => {
    try {
      const credential = PhoneAuthProvider.credential(
        confirm.verificationId,
        code
      );

      await signInWithCredential(getAuth(), credential);
      alert("Authenticated successfully");
    } catch (error) {
      console.error("Error confirming code:", error);
      alert("Invalid code.");
    }
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();
    await confirmCode();

    setShowPopup(false);

    // Delete the item after successful authentication
    await moveItemToSold(selectedItemId);
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
  };

  const filteredItems = items.filter(
    (item) =>
      item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div className="px-8 pb-8 mt-2 flex-col justify-center items-center flex bg-transparent">
      <p className="font-dmserif mt-2 text-black text-2xl font-normal tracking-wider text-center">
        Welcome to the market
      </p>
      <DebounceInput
        minLength={1}
        debounceTimeout={300}
        placeholder="Refine your search..."
        onChange={handleSearchChange}
        className="shadow-custom focus:outline-none border-0 border-none font-dmserif mt-2 text-black text-lg font-normal tracking-wider max-w-xl"
      />

      <div className="item-grid bg-transparent">
        {filteredItems.map((item) => (
          <div
            key={item.id}
            className="bg-white rounded-lg p-3 shadow-custom justify-center items-center flex flex-col"
          >
            <img
              src={item.image}
              alt={item.title}
              className="rounded-lg mb-1 bg-transparent"
            />
            <div className="flex flex-col justify-between items-start w-full bg-transparent">
              <p className="bg-transparent text-2xl text-bold font-dmserif tracking-wider">
                {item.title}
              </p>
              <p className="bg-transparent text-base text-bold font-dmserif tracking-wider">
                Price: ${parseFloat(item.price).toFixed(2)}
              </p>
            </div>
            <p className="font-dmserif mt-2 text-black text-xl font-light tracking-wider">
              Contact: {item.phoneNumber}
            </p>
            <button
              className="bg-[#EC7D5A] mt-4 justify-center items-center py-2.5 px-7 rounded-lg w-full"
              onClick={() => handleDelete(item.id)}
            >
              <p className="font-inria text-white text-xl font-normal tracking-wder text-center">
                Mark as Sold
              </p>
            </button>
          </div>
        ))}
      </div>
      {isLoading ? (
        <div className="font-dmserif mt-8 text-black text-2xl font-normal tracking-wider text-center bg-transparent">
          Loading...
        </div>
      ) : null}
      {showPopup && (
        <div className="popup">
          <div className="popup-content bg-white">
            <form>
              <p
                className="font-dmserif mt-2 text-black text-xl font-normal tracking-wider text-center"
                htmlFor="phone-number"
              >
                Enter your phone number:
              </p>
              <input
                type="text"
                id="phone-number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="focus:outline-none mt-2 border-0 border-none font-dmserif text-black text-lg font-normal tracking-wider"
              />
              <button
                className="bg-[#EC7D5A] mt-4 justify-center items-center py-2.5 px-7 rounded-lg"
                type="button"
                onClick={signInWithPhoneNumber}
              >
                <p className="font-inria text-white text-base font-normal tracking-wder text-center">
                  Send Verification Code
                </p>
              </button>
              {showConfirmationCode && (
                <>
                  <p
                    className="font-dmserif mt-2 text-black text-xl font-normal tracking-wider text-center"
                    htmlFor="confirmation-code"
                  >
                    Confirmation Code:
                  </p>
                  <input
                    type="text"
                    id="confirmation-code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                    className="focus:outline-none mt-2 border-0 border-none font-dmserif text-black text-lg font-normal tracking-wider"
                  />

                  <button
                    className="bg-[#EC7D5A] mt-4 justify-center items-center py-2.5 px-7 rounded-lg"
                    type="submit"
                    onClick={handlePopupSubmit}
                  >
                    <p className="font-inria text-white text-base font-normal tracking-wder text-center">
                      Submit
                    </p>
                  </button>
                </>
              )}
              <button
                className="bg-[#EC7D5A] mt-4 justify-center items-center py-2.5 px-7 rounded-lg"
                type="button"
                onClick={handlePopupCancel}
              >
                <p className="font-inria text-white text-base font-normal tracking-wder text-center">
                  Cancel
                </p>
              </button>
            </form>
          </div>
        </div>
      )}
      <div id="recaptcha-container"></div>
    </div>
  );
}

const useInfiniteScroll = () => {
  const [isNearBottom, setIsNearBottom] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 500
      ) {
        setIsNearBottom(true);
      } else {
        setIsNearBottom(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isNearBottom;
};

export default Market;
