import React from "react";
import "./Home.css";
import { FaUniversity, FaShoppingCart, FaMoneyCheckAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="px-8 pb-8 mt-2 flex-col justify-center items-center flex bg-transparent">
      <FaUniversity className="college-icon" size={45} />
      <p className="font-dmserif mt-2 text-black text-2xl font-normal tracking-wider text-center">
        The best marketplace <br /> for college students
      </p>
      <div className="bg-[#EC7D5A] mt-4 shadow-custom justify-center items-center py-2.5 px-7 rounded-lg">
        <Link to="/market">
          <p className="font-inria text-white text-xl font-normal tracking-wder text-center">
            Go to market
          </p>
        </Link>
      </div>
      <div className="h-12"></div>
      <FaShoppingCart className="sell-buy-icon" size={45} />
      <p className="font-dmserif mt-2 text-black text-2xl font-normal tracking-wider text-center">
        Easily sell or buy <br /> used goods
      </p>
      <div className="bg-[#EC7D5A] mt-4 shadow-custom justify-center items-center py-2.5 px-7 rounded-lg">
        <Link to="/make-listing">
          <p className="font-inria text-white text-xl font-normal tracking-wder text-center">
            List Your Item
          </p>
        </Link>
      </div>
      <div className="h-16"></div>

      <FaMoneyCheckAlt className="fees-icon" />
      <p className="font-dmserif mt-2 text-black text-2xl font-normal tracking-wider text-center">
        No added fees!
      </p>
    </div>
  );
}

export default Home;
