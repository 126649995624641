import React from "react";
import "./MakeListing.css";
import ProductForm from "../components/ProductForm";

function MakeListing() {
  return (
    <div className="px-8 pb-8 mt-2 flex-col justify-center items-center flex bg-transparent">
      <ProductForm></ProductForm>
      <p className="disclaimer">
        The Fikko platform is not responsible for any damages, lost items,
        payments, or any other issues that may arise during the buying or
        selling process. We are simply providing a platform for students to
        connect and conduct transactions, and any issues or disputes should be
        resolved between the buyer and seller.
      </p>
    </div>
  );
}

export default MakeListing;
